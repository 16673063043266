@import '../../../styles/main.scss';

.fastagDetailsWrapper {
  padding: $universalSize-8 $universalSize-30 0;

  @include respond(medium){
    padding: $universalSize-8 $universalSize-10;
  }
}

.closeBtn {
  text-align: end;
  cursor: pointer;
  margin-bottom: $universalSize-15;
}

.fastagDetailContainer {
}

.fastagFlex {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.fastagVehicleDetailsWrapper {
  border: 1px solid $white-color-3;
  background: $white-color-5;
  padding: $universalSize-8;
  justify-content: space-between;
  margin: $universalSize-12 0 0;
  border-top-right-radius: $universalSize-12;
  border-top-left-radius: $universalSize-12;
}

.editFastag {
  display: flex;
  margin-top: $universalSize-8;
  cursor: pointer;
  align-items: center;
  margin-left: $universalSize-20;
  @include respond(medium) {
    width: 70%;
    margin-left: $universalSize-10;
  }
   .editIcon{
    margin-left: $universalSize-20;
  }
}

.bankDetail {
  display: flex;
  align-items: center;
}

.bankName {
  margin-left: $universalSize-8;
}

.fastagBalanceAndMore {
  display: flex;
  padding: $universalSize-16;
  flex-direction: column;
  border: 1px solid $white-color-3;
  border-bottom: 0;
}

.currentBalance {
  text-align: center;
  align-self: center;

  p {
    color: $text-color-3;
    font-size: $font-size-xsmall;
    font-weight: $font-weight-para-heading;
    margin-bottom: $universalSize-16;
  }

  span {
    color: $text-color;
    font-size: $font-size-normal;
    font-weight: $font-weight-para-heading;
  }
}

.viewDetails {
  border-top: 1px solid $white-color-3;
  padding: $universalSize-8 $universalSize-16;
  border-bottom-left-radius: $universalSize-12;
  border-bottom-right-radius: $universalSize-12;
  border: 1px solid $white-color-3;
  box-shadow: 0px 4px 6px -2px $box-shadow-color-1;
}

.viewDetailsHeading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: $light-blue-color;
  font-size: $font-size-xsmall;
  font-weight: $font-weight-section-heading;
  cursor: pointer;
  margin: $universalSize-8 0 $universalSize-16 0;
}

.detailsVisible {
  margin-top: $universalSize-8;
}

.viewDetail {
  display: flex;
  justify-content: space-between;
  margin: $universalSize-8 0;
  padding-bottom: $universalSize-8;
  border-bottom: 1px solid $white-color-3;

  &:last-child {
    border-bottom: none;
  }

  p {
    color: $text-color-3;
    font-size: $font-size-xsmall;
    font-weight: $font-weight-para-heading;
  }

  span {
    color: $text-color;
    text-align: right;
    font-size: $font-size-xsmall;
    font-weight: $font-weight-500;
  }
}

.banner {
  margin: $universalSize-16 0;
  background: 
    linear-gradient(106deg, #611BCD -16.56%, #2F0D63 100%), 
    url('/images/fastag/desert.png') no-repeat center;
  background-size: cover;
  background-blend-mode: overlay; /* This allows the image to appear on top of the gradient */
  border-radius: $universalSize-12;
  padding: $universalSize-24;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  text-align: center;
}

.bannerHeading {
  h1 {
    color: $white-color;
    font-size: $font-size-normal;
    font-weight: $font-weight-section-heading;
    margin-bottom: $universalSize-4;
  }

  span {
    color: $white-color;
    font-size: $font-size-xsmall;
    font-weight: $font-weight-section-heading;
  }
}

.bannerContent {
  display: flex;
  justify-content: space-between;
  margin-top: $universalSize-16;
  align-items: center;

  @include respond(medium) {
    flex-direction: column;
  }
  .bannerBulletPoints {
    width: 60%;

    @include respond(medium) {
      width: 100%;
    }

    div {
      display: flex;
      padding: $universalSize-8 $universalSize-16;
      align-items: center;
      border-radius: $universalSize-12;
      border: 1px solid $white-color-3;
      background: $white-color;
      box-shadow: 0px 4px 6px -2px $box-shadow-color-1;
      margin-bottom: $universalSize-16;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        margin-right: $universalSize-20;
      }
    }
  }
}

.downloadScannerContainer {
  background-color: $white-color;
  border-radius: $universalSize-12;
  width: $universalSize-153;
  height: $universalSize-150;

  @include respond(medium) {
    display: none;
  }
}

.downloadScanner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .scannerImg {
    width: $universalSize-130;
    height: $universalSize-130;
    object-fit: contain;
  }
}

.toggelSvg {
  transform: rotate(180deg);
}

.downloadApp {
  width: 100%;
  display: none;

  @include respond(medium) {
    display: block;
  }

  button {
    margin-top: $universalSize-24;
    display: flex;
    height: $universalSize-60;
    padding: $universalSize-10 $universalSize-20;
    justify-content: center;
    align-items: center;
    border-radius: $universalSize-8;
    background: $button-background-color;
    width: 100%;
    outline: none;
    border: none;
    color: $white-color;
    font-size: $font-size-small;
  }
}

.noFastagFound {
  text-align: center;
  border: 1px solid $white-color-3;
  padding: $universalSize-16;
  border-bottom-right-radius: $universalSize-12;
  border-bottom-left-radius: $universalSize-12;
  align-items: center;
  min-height: $universalSize-100;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btnTryAgain{
  width: 100%;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: $universalSize-12;
  background-color: $white-color;
  border: 1px solid black;
  cursor: pointer;
  margin-top: 32px;
  height: 48px;
  font-size: $font-size-xsmall;
  margin-bottom: $universalSize-16;
}

.noFastagContainer{
  padding: $universalSize-16;
  border: 1px solid $white-color-3;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  text-align: center;
  box-shadow: 0px 4px 6px -2px $box-shadow-color-1;
}