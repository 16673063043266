@import '../../styles/main.scss';

.shimmerContainer {
  height: $universalSize-471;
  width: $universalSize-471;
  background-color: $shimmer-color-1;
  position: relative;
  overflow: hidden;
  border-radius: $universalSize-24;
  box-shadow: 0px 2px $universalSize-100 0px rgba(18, 18, 18, 0.08);

  @include respond(medium){
    display: none;
  }

  .shimmer {
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, $shimmer-color-1 8%, $shimmer-color-2 38%, $shimmer-color-1 54%);
    position: absolute;
    top: 0;
    left: 0;
    animation: shimmer $animation-duration infinite;
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

