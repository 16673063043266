@import '../../../styles/main.scss';

.noFastagHeading{
    color: $text-color !important;
    font-size: $font-size-normal;
    font-weight: $font-weight-section-heading;
    line-height: $line-height-normal;
    margin-bottom: $universalSize-4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noFastagPara{
    color: #5B5675;
    font-size: $font-size-xsmall;
    line-height: $line-height-xsmall;
}