@import '../../../styles/main.scss';

.widgetContainer {
  width: $universalSize-510;

  @media (min-width: 761px) and (max-width: 910px) {
    width: 25rem;
  }

  @include respond(small) {
    width: 100%;
  }

  .ButtonContainer {
    .renderWidgetButton {
      display: none;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      width: 90vw;

      @media (min-width: 481px) and (max-width: 759px) {
        width: 78vw;
      }

      span {
        display: block;
        @include desktopPara3();
        font-size: 16px;
        text-align: center;
        color: $white-color;
        background: $button-background-color;
        border-radius: $universalSize-8;
        padding: $universalSize-16;
        font-weight: 600;
        // width: 90vw;

        // @media (min-width: 481px) and (max-width: 759px) {
        //   width: 100%;
        // }
      }
      @include respond(medium) {
        display: block;
      }
    }
  }
}

.lifeWidgetVisibleMobile {
  display: none;

  #OverlayContainer {
    padding-right: 24px;
    margin-right: -24px;
    height: 60vh;
  }

  @include respond(medium) {
    display: block;
  }
}

.lifeWidgetVisibleDesktop {
  display: block;

  div[id='DesktopWidgetContainer'] {
    width: 100%;
    // .isFloating {
    //   max-height: 94vh;
    //   padding-bottom: 0px;
    // }
  }

  @include respond(medium) {
    display: none;
  }
}

.addScroll {
  @include addScroll();
  @include removeScrollBar();
}
